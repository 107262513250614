import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Seo from "../components/seo";
import Hero from "../components/hero";
import Highlights from "../components/highlights";
import Trainings from "../components/trainings";
import Devstyle from "../components/devstyle";
import Reviews from "../components/reviews";
import Companies from "../components/companies";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Hero mdxType="Hero" />
    <Highlights mdxType="Highlights" />
    <Trainings mdxType="Trainings" />
    <Devstyle mdxType="Devstyle" />
    <Reviews mdxType="Reviews" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      